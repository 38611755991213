var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    this.authorized
      ? _c("div", [
          _vm.creditsChecked
            ? _c("div", { staticClass: "p-5" }, [
                _c("div", [
                  _vm.success
                    ? _c("p", { staticStyle: { color: "green" } }, [
                        _vm._v(
                          " \n\t\t\t\t\t\tResult: " +
                            _vm._s(_vm.success) +
                            "\n\t\t\t\t\t"
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("You "),
                    _vm.success
                      ? _c("span", [_vm._v(" currently ")])
                      : _vm._e(),
                    _vm._v(" have " + _vm._s(_vm.credits) + " gps credits")
                  ]),
                  _vm._v(" "),
                  _vm.type == "monthly"
                    ? _c("p", [
                        _vm._v(
                          " \n\t\t\t\t\t\t(left this month - regular users get 10 a month)\n\t\t\t\t\t"
                        )
                      ])
                    : _vm.type == "credits"
                    ? _c("p", [
                        _vm._v(
                          "\n\t\t\t\t\t\t(in your available download credits from uploading GPS data, taking from this total as your monthly is used up)\n\t\t\t\t\t"
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.credits > 0
                    ? _c("div", [
                        _c("p", [
                          _vm._v(
                            " Click the download button to get this GPS / GPX. CAUTION: each click uses a credit."
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "form",
                          {
                            ref: "gform",
                            staticStyle: { padding: "5px" },
                            attrs: {
                              action: "/trails/download-gps",
                              method: "post"
                            }
                          },
                          [
                            _c("input", {
                              attrs: { type: "hidden", name: "_token" },
                              domProps: { value: _vm.csrf }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { type: "hidden", name: "tid" },
                              domProps: { value: _vm.tid }
                            }),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { type: "hidden", name: "uid" },
                              domProps: { value: _vm.uid }
                            }),
                            _c("br"),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-submit btn-secondary",
                                on: { click: _vm.submit }
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t  \t\tDownload GPS/GPX file\n\t\t\t\t\t\t  \t"
                                )
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                !_vm.errors && _vm.credits <= 0
                  ? _c("div", [
                      _c("p", [
                        _vm._v(
                          "You don't have any download credits (remaining), please login (every logged in user gets 10 downloads a month), or, add GPS (as .GPX) data for any trail that needs it to get 3 download credits added to your total."
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.errors
                  ? _c(
                      "div",
                      { staticClass: "ml-3", attrs: { id: "download-msg" } },
                      [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.errors))
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      : _c("div", [
          _c("p", [
            _vm._v(
              "Please make sure you are logged in and have credits, all logged in users get free 10 GPS downloads each month, and if you add a GPX file for any trail that we need GPS for, you get 3 credits added to your account that never expire."
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }