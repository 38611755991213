<template>
    
   <div>
		<!-- show Gps -->
 		<div v-if="this.authorized">
 			<div v-if="creditsChecked" class="p-5">
				<div>
					<p v-if="success" style="color:green;"> 
						Result: {{ success }}
					</p>
					<p>You <span v-if="success"> currently </span> have {{ credits }} gps credits</p>
					<p v-if="type == 'monthly'"> 
						(left this month - regular users get 10 a month, subscribers get 25)
					<p v-else-if="type == 'credits'">
						(in your available download credits from uploading GPS data, taking from this total as your monthly is used up)
					</p>
					<div v-if="credits > 0">
						<p> Click the download button to get this GPS / GPX. CAUTION: each click uses a credit.</p>
						<form action="/trails/download-gps" method="post" style="padding:5px" ref="gform">
						    <input type="hidden" name="_token" :value="csrf">
						  	<input type="hidden" name="tid" :value="tid">
						  	<input type="hidden" name="uid" :value="uid"><br><br>
						  	<button v-on:click="submit" class="btn btn-submit btn-secondary">
						  		Download GPS/GPX file
						  	</button>
						</form>
					</div>
				</div>
		 		<div v-if="!errors && credits <= 0">
					<p>You don't have any download credits (remaining), please login (every logged in user gets 10 downloads a month), or, add GPS (as .GPX) data for any trail that needs it to get 3 download credits, or, become a <a href="/users/pay/setup">subscriber</a> for a loonie a moonth and get even more downloads and other cool features.</p>
				</div>
				<div id="download-msg" v-if="errors" class="ml-3">
		 			<span style="color:red;">{{ errors }}</span>
		 		</div>
			</div>

 		</div>
 		<div v-else>
			<p>Please make sure you are logged in and have credits, all logged in users get free 10 GPS downloads each month, premium subscribers get 25 GPS downloads a month and a bunch of other extra features for a loonie a month!</p>
		</div>

   </div> 
</template>

<script>
    import prettyJSON from "../utilities/prettyJson.js";
    import { toRefs } from 'vue'
    export default {
        name: 'download-gps',
        props: {
        	tid : {
				default: null,
			},
            uid : {
				default: null,
			},
            auth: {
				default: null,
			}
        },
        data() {
            return {
                creditsChecked: false,
                downloaded: false,
                credits: 0,
                type: '',
                authorized: false,
			    errors: '',
			    success: '',
			    csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        methods: {
		    checkCredits() {
				this.downloaded = false; 
				let formData = new FormData();
				this.errors = '';

				if (this.authorized) {
		            formData.set('uid', this.uid);
		            formData.set('auth', this.authorized);

			        axios.post('/trails/check-gps-credits', formData,
			            ).then(response => {
			            	this.creditsChecked = true;
			            	this.credits = parseInt(response.data.result);
			            	this.type = response.data.type;
			            	return true;
		                })
				        .catch(error => {
				        	this.creditsChecked = true;
				        	this.credits = 0;

		                    if (error.response !== null) {
		                        if (error.response.data){
		                            this.errors = error.response.data.errors;
		                            console.log("GPS call FAILURE: " + JSON.stringify(error.response.data)); 
		                        }
		                    } else {
		                        console.log("unknown gps download error: " + error.response);
		                        this.errors = ' unknown error, please contact us';
		                    }
		                });

			    } else {
				    // console.log("NO AUTH");
			    }

	            return false;
	            	
		    },
		    wait (time) {
			    return new Promise(resolve => {
			        setTimeout(resolve, time);
			    });
		    },
		    async submit() {
		    	this.downloaded = true;
		    	this.success = 'Success! ';

		    	this.$refs.gform.submit();
		    	await this.wait(200);

		    	this.checkCredits(); // get an update

		    	if (this.credits == 0) {
		    		if (this.type == 'monthly') {
		    			this.errors = 'NOTE: You get a new batch of download credits next month, but you can also add GPS data to a trail and earn credits, 3 for every one GPX file you add that we approve.';
		    		} else {
		    			this.errors = 'NOTE: Add GPS (.GPX) data for another 3 download credits or subscribe to get 25 monthly downloads.  Get out there!';
		    		}
		    	}
		    },
		},
        mounted() {
    		this.authorized = this.auth;
    		this.checkCredits();
        },
        destroyed() {
		    //console.log('Component has been destroyed!');
		}
    }

</script>

<style>
	#map {
		height: 350px;
	}
	.form-control {
		padding: 0.275rem 0.75rem;
	}
</style>